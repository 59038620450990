footer {
  //   padding: 50px;
  display: flex;
  flex-direction: column; /* Ensure children are stacked vertically */
  height: 100%;
  background: linear-gradient(358.5deg, #ffffff 68.68%, #58e6f9 99.95%);
  .footer-menu {
    display: flex;
    height: fit-content;
    gap: 50px;
  }

 

  .footer-content {
    padding: 50px;  
    display: flex;
    justify-content: space-between;
    


    .footer-rights{
      color: #9B9B9C;
      font-size: 16px;
      font-family: Outfit;
      font-weight: 300;
      word-wrap: break-word;
      margin-top: 80px;
    }
    .elevate-your {
      display: flex;
      flex-direction: column;
      // font-size: 48px;
      // font-weight: bold;
      // line-height: 1.2;
      font-family: 'Outfit';

      .changing-word {
        transition: transform 0.5s ease-in;
      }
    }

    .menu,
    .license,
    .contact-info {
      h4 {
        font-size: 18px;
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding: 0;
        li {
          font-size: 16px;
          color: #9b9b9c;
          margin-top: 10px;
        }
      }
    }
  }
}

// Animation for changing word in footer
.footer-content .elevate-your .changing-word {
  animation: changeWord 2s infinite;
}

@keyframes changeWord {
  0%,
  33.33% {
    transform: translateY(0);
  }
  66.66%,
  100% {
    transform: translateY(-100%);
  }
}
@media (max-width: 768px) {
  footer {  
    .footer-content {
      margin-top: 80px;
      display: flex;
      flex-direction: column-reverse;
      background: linear-gradient(360deg, rgba(198, 241, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%, rgba(255, 255, 255, 0.6) 100%);
    }

    .footer-rights{
      margin-top: 1px !important ; 
      margin-bottom: 1px !important; 

    }
  }
}


// .divider {
//     width: 56px;
//     @media (max-width: 768px) {
//         display: none;
//     }
// }
