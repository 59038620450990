.companies-logos {
  // position: absolute;
  // bottom: 10px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.logos-scrolls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: scroll 15s linear infinite; 
}

.logoImg {
  height: 20px;
  margin: 0 30px;
}

.logos-scroll::before,
.logos-scroll::after {
  content: '';
  display: inline-block;
  width: 100%;
  background: url('../../assets/images/icons/Gaskonlogo.svg') center no-repeat;
  background-size: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .logos-scrolls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: scroll 5s linear infinite !important; 
  }
}