.recent-activities {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 7px rgba(31.08, 37.65, 57.37, 0.04);
    border: 1px solid #e1e5e4;
    height: 100%;
  
    .header {
      margin-bottom: 20px;
  
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  
    .activity-list {
      .activity {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e1e5e4;
  
        .avatar {
          width: 32px;
          height: 32px;
          background: linear-gradient(0deg, #dfdfdf 0%, #dfdfdf 100%);
          border-radius: 50%;
          margin-right: 10px;
        }
  
        .info {
          flex-grow: 1;
  
          span {
            color: #686868;
            font-size: 14px;
          }
  
          strong {
            font-size: 14px;
            color: #1e1e1e;
          }
  
          time {
            display: block;
            color: #989898;
            font-size: 12px;
            margin-top: 5px;
          }
  
          .feedback {
            padding: 10px;
            background: rgba(224.72, 228.97, 227.52, 0.24);
            border-radius: 8px;
            border-right: 2px solid #e1e5e4;
            border-bottom: 2px solid #e1e5e4;
            margin-top: 10px;
          }
        }
      }
    }
  }
  