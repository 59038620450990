// .sidebar {
//   padding: 20px;

//   .logo {
//     font-size: 24px;
//     font-weight: bold;
//     margin-bottom: 20px;
//   }

//   nav {
//     ul {
//       list-style: none;
//       padding: 10px;

//       li {
//         width: 100%;
//         padding: 12px;
//         font-size: 16px;
//         color: #686868;
//         border-radius: 8px;
//         justify-content: flex-start;
//         align-items: center;
//         gap: 16px;
//         display: inline-flex;

//         &.active {
//           color: black;
//           font-weight: bold;
//           background-color: #e6f1fa;
//         }
//       }
//     }
//   }

//   .icon {
//     margin-right: 16px;
//   }

//   .logout {
//     margin-top: auto;
//     color: #686868;
//     cursor: pointer;
//   }
// }
// .sidebar {
//   transition: width 0.3s ease-in-out;

//   &.closed {
//     width: 60px;

//     .sidebar-header {
//       justify-content: center;

//       img {
//         display: none;
//       }
//     }

//     .menu-list {
//       .menu-item {
//         span {
//           display: none;
//         }
//       }
//     }

//     .logout {
//       span {
//         display: none;
//       }
//     }
//   }
//   .menu-list {
//     padding: 10px;
//     .menu-item {
//       width: 100%;
//       padding: 12px;
//       font-size: 16px;
//       color: #686868;
//       border-radius: 8px;
//       justify-content: flex-start;
//       align-items: center;
//       gap: 16px;
//       display: inline-flex;
//       &.active {
//         color: black;
//         font-weight: bold;
//         background-color: #e6f1fa;
//       }
//     }
//   }

//   .sidebar-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 50px;

//     .toggle-sidebar {
//       background-color: transparent;
//       border: none;
//       cursor: pointer;
//       font-size: 24px;
//       color: #333;
//     }
//   }

//   .menu-list {
//     list-style-type: none;
//     padding: 0;
//     margin: 0;

//     .menu-item {
//       padding: 10px;
//       cursor: pointer;
//       border-radius: 5px;
//       font-size: 1rem;

//       &.active {
//         background-color: #e6f1fa;
//       }

//       span {
//         margin-left: 0px;
//       }
//     }
//   }

//   .logout {
//     position: absolute;
//     bottom: 20px;
//     display: flex;
//     align-items: center;
//     cursor: pointer;

//     svg {
//       margin-right: 10px;
//     }
//   }
// }


.sidebar {
  padding: 20px;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  width: 250px;

  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  nav {
    ul {
      list-style: none;
      padding: 10px;

      li {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        color: #686868;
        border-radius: 8px;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        display: inline-flex;

        &.active {
          color: black;
          font-weight: bold;
          background-color: #e6f1fa;
        }
      }
    }
  }

  .icon {
    margin-right: 16px;
  }

  .logout {
    margin-top: auto;
    color: #686868;
    cursor: pointer;
  }

  &.closed {
    width: 60px;

    .sidebar-header {
      justify-content: center;

      img {
        display: none;
      }
    }

    .menu-list {
      .menu-item {
        span {
          display: none;
        }
      }
    }

    .logout {
      span {
        display: none;
      }
    }
  }

  .menu-list {
    padding: 10px;
    .menu-item {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      color: #686868;
      border-radius: 8px;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      display: inline-flex;
      &.active {
        color: black;
        font-weight: bold;
        background-color: #e6f1fa;
      }
    }
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .toggle-sidebar {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 24px;
      color: #333;
    }
  }

  .menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .menu-item {
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1rem;

      &.active {
        background-color: #e6f1fa;
      }

      span {
        margin-left: 0px;
      }
    }
  }

  .logout {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }
}

// Mobile responsiveness
@media (max-width: 768px) {
  .mobile-header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // height: 60px;
    background-color: white;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    border-bottom: .5px solid #808B8B;
    // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1001;
  }

  .mobile-toggle-sidebar,
  .mobile-search {
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
  }

  .mobile-logo {
    height: 30px;
  }

  .sidebar {
    top: 60px;
    height: calc(100vh - 60px);
    transform: translateX(-100%);
    width: 250px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar.closed {
    transform: translateX(-100%);
    width: 250px;
  }

  .sidebar .sidebar-header {
    display: none;
  }

  .sidebar.closed .menu-list .menu-item span,
  .sidebar.closed .logout span {
    display: inline;
  }

  .toggle-sidebar {
    display: none;
  }
}
.mobile-header {
  display: none;
}

.mobile-toggle-sidebar,
.mobile-search {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.mobile-logo {
  height: 20px;
}

@media (max-width: 768px) {
  .mobile-header {
    display: flex;
  }

  .sidebar {
    top: 60px;
    height: calc(100vh - 60px);
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar .sidebar-header {
    display: none;
  }
}
