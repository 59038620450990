.dashboard-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f0f0f0;
}

.sidebar {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
  padding-top: 32px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  .logo {
    width: 90px;
    height: 16px;
    margin-bottom: 50px;
  }

  .sidebar-item {
    width: 215px;
    height: 35px;
    background: #d9d9d9;
    margin-bottom: 27px;
  }

  .sidebar-footer {
    width: 215px;
    height: 72px;
    background: #d9d9d9;
    margin-top: auto;
    margin-bottom: 20px;
  }
}

.dashboard-layout {
  display: flex;
  @media (min-width: 768px) {
    &.side-close {
      .client-dashboard-main-content {
        width: calc(100% - 50px) !important;
        margin-left: 50px !important;
      }
    }
    &.side-open {
      .client-dashboard-main-content {
        width: calc(100% - 250px);
        margin-left: 250px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 70px;
    background: #f7f7f7;
  }

  &.side-close {
    .client-dashboard-main-content {
      flex-grow: 1;
      background: #f7f7f7;
      padding: 20px;
      height: 100%;
      overflow-y: scroll;
    }
  }
  &.side-open {
    .client-dashboard-main-content {
      flex-grow: 1;
      background: #f7f7f7;
      padding: 20px;
      height: 100%;
      overflow-y: scroll;
    }
  }
}

.content-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.main-content {
  flex: 1;
  padding: 25px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .header-item {
      width: 734px;
      height: 35px;
      background: #d9d9d9;
    }

    .create-portfolio {
      padding: 12px 28px;
      background: #010205;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .create-portfolio-text {
        color: white;
        font-size: 16px;
        font-family: 'Outfit', sans-serif;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }

  .content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .content-box {
      background: white;
      box-shadow: 0px 6px 7px rgba(31, 37, 57, 0.04);
      border: 1px solid rgba(33, 39, 50, 0.04);
    }

    .content-box:first-of-type {
      width: 795px;
      height: 192px;
    }

    .content-box:nth-of-type(2) {
      width: 795px;
      height: 476px;
    }

    .content-box:last-of-type {
      width: 795px;
      height: 476px;
    }

    .sidebar-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .sidebar-content-box {
        background: white;
        box-shadow: 0px 6px 7px rgba(31, 37, 57, 0.04);
        border: 1px solid rgba(33, 39, 50, 0.04);
      }

      .sidebar-content-box:first-of-type {
        width: 307px;
        height: 222px;
      }

      .sidebar-content-box:nth-of-type(2) {
        width: 307px;
        height: 122px;
      }

      .sidebar-content-box:last-of-type {
        width: 307px;
        height: 310px;
      }
    }
  }
}
