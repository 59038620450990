.admin-login-container {
    display: flex;
    background: white;
  }
  
  .admin-image-ctnr-admin {
    overflow: hidden;
    position: relative;
  }
  
  .admin-background-im-adm {
    height: 100%;
    justify-content: left;
    object-fit: cover;
    mix-blend-mode: multiply;
  }
  
  .admin-login-content {
    padding-left: 150px;
    padding-top: 5%;
    padding-right: 150px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .admin-sign-in-text {
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    margin-bottom: 32px;
  }
  
  .admin-elevate-text {
    color: #1E1E1E;
    font-size: 48px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2rem;
    margin-bottom: 40px;
  }
  
  .admin-login-form {
    width: 484px;
    box-shadow: 0px 24px 54px rgba(24, 24, 24, 0.07);
    border-radius: 8px;
    border: 1px #E1E5E4 solid
    // padding: 40px;
  }
  
  .admin-divider {
    height: 1px;
    width: 100%;
    background-color: #E1E5E4;
    margin-bottom: 8px;
  }
  
  .admin-input-container {
    padding-left: 32px;
    padding-top: 24px;
  }
  
  .admin-input-container label {
    display: block;
    color: #989898;
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .admin-input-container input {
    width: 90%;
    padding-bottom: 12px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    color: #2B3940;
    outline: none;
  }
  
  .admin-forgot-password-container {
    display: flex;
    justify-content: flex-end;
    width: 484px;
    margin-top: 33px;
  }
  
  .admin-forgot-password {
    color: #989898;
    font-size: 12px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    letter-spacing: 1.20;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .admin-sign-in-button {
    width: 484px;
    padding: 16px;
    background: #1E1E1E;
    color: white;
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin-top: 20px;
  }
  