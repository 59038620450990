.founder-section {
  // padding: 20px;
  font-family: Arial, sans-serif;
  background: #FFFFFF;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 100px 40px;
    // max-width: 1280px;
    // margin: 0 auto;
  }

  .founder-intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    h1 {
      // font-size: 2rem;
      font-weight: 900;
      margin: 0;
      color: #2a2a2a;

      @media (min-width: 768px) {
        font-size: 4.5rem;
      }
    }
    // .founder-image {
    //   // background-color: #000;
    //   height: 380px;
    //   width: 280px;
    //   border-radius: 16px;
    //   // padding: 50px;
    //   img {
    //     //   border-radius: 50%;
    //     width: 200px;
    //     height: auto;
    //   }
    // }

    .highlightIcon {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .founder-details {
    margin-left: 30px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      // margin-top: 10px;
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      color: #7c8086;
    }
  }

  .flexCol {
    @media (min-width: 768px) {
      width: 65%;
    }

    h4 {
      text-align: left;
      margin: 0;
      padding-left: 20px;
      font-size: 1.2rem;
      // margin-bottom: 1rem;
    }
  }

  .timeline {
    max-width: 400px;
    padding: 10px 20px;
    text-align: left;
    position: relative;

    span {
        color : #7C8086;
        font-size: 1rem;
        font-weight: 400;
    }
  }

  .tl {
    border-left: 2px #4bbb90 solid;
  }

  .tp {
    @media (min-width: 768px) {
      border-top: 2px #4bbb90 solid;
    }
  }

  .tl-circle {
    position: absolute;
    top: -6.5px;
    left: -6.5px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px #4bbb90 solid;
    background-color: #fff;
  }

  .flexRow {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
    }
  }

  // Mobile-specific styles
  @media (max-width: 767px) {
    .timeline {
      border-left: 2px solid #4bbb90;

      &:last-child {
        border-left: none;
        // padding: 0 20px;
      }

      .tl-circle {
        left: -7px;
      }

      &:last-child .tl-circle {
        background-image: url('../assets/images/GreenFlag.png');
        background-size: cover;
        width: 18px;
        height: 18px;
        left: -9px;
        border: none;
      }
    }
  }
}
