.container {
  background-color: #f6fffc;
  position: relative;
  max-width: 100% !important;
}
.evolveBgDark{
  background-image: url('../../../../assets/images/evolveEnergy/evolveBgDark.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 768px) {
  .additional-benefits-container {
    padding: 4rem 20px;
    .headingTitle {
      margin-left: 15px !important;
    }
  }

  .reapGrant {
    .eligible-projects {
      background-color: #339971 !important;
    }
  }
  .key-opportunities {
    padding: 50px 20px !important;
  }
  .investment-opportunity-page {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .content-wrapper {
    flex: 1 1 0;
    align-self: stretch;
    width: 100%;
    padding: 24px;
    padding-top: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    position: relative;
    // z-index: 2;
    min-height: 90vh;
  }

  .headingTitle {
    position: relative;
    margin-left: 40px;

    .highlightImage {
      position: absolute;
      top: -18px;
      left: -22px;
      height: 34px;
    }
  }

  .logo {
    width: 250px;
    height: 50px;
    margin-bottom: 0px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .heading {
    color: white;
    font-size: 48px;
    font-weight: 600;
    text-transform: uppercase;
    word-wrap: break-word;
    width: 100%;
  }

  .year-wrapper {
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    display: inline-flex;
  }

  .year-highlight {
    width: 4px;
    height: 24px;
    position: relative;
  }

  /* New styles for the Executive Summary section */
  .executive-summary-section {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    // background: linear-gradient(0deg, #f6fffc, #f6fffc),
    //   linear-gradient(
    //     262.09deg,
    //     rgba(206, 250, 233, 0.56) 6.48%,
    //     rgba(221, 237, 231, 0.56) 94.97%
    //   );

    // height: 100vh; /* Full viewport height */
  }

  .left-side {
    width: 100%;
    height: 60vh;
    position: relative;
  }

  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .right-side {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background: linear-gradient(
      262deg,
      rgba(206, 250, 233, 0.56) 0%,
      rgba(220, 237, 230, 0.56) 100%
    );
    position: relative;
  }

  .summary-content-wrapper {
    // max-width: 600px;
  }

  .summary-heading {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .summary-icon {
    width: 4px;
    height: 40px;
  }

  .summary-title {
    color: #2a2a2a;
    font-size: 36px;
    font-family: 'Test Founders Grotesk';
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
  }

  .summary-text {
    margin-top: 16px;
    color: #676767;
    font-size: 18px;
    font-family: 'Outfit';
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
    max-width: 475px;
  }
  .energy-partnerships-container {
    padding: 16px;
    text-align: center;
    padding-bottom: 64px;

    .heading-section {
      margin-bottom: 40px;
      margin-top: 60px;
      width: 100%;
      text-align: left;

      h1 {
        font-weight: 600;
        font-size: 1.8rem;
        color: #2a2a2a;
        margin-bottom: 20px;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #2a2a2a;
        font-weight: 400;
      }
    }

    .icons-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      padding-left: 24px;

      .icon-item {
        width: 40%;
        // margin: 20px 0;
        text-align: left;
        // padding: 20px 40px;

        img {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }

        span {
          display: block;
          font-size: 1rem;
          font-weight: 600;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
  }

  .market-tailwind-container {
    display: flex;
    flex-direction: column;
    background-color: #3dba91; // Assuming this is the background color from the image
    padding: 60px 16px;
    color: #fff;
    background-image: url('../../../../assets/images/evolveEnergy/greenBgMbl.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .left-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 60px;

      h1 {
        font-size: 3rem;
        font-weight: bold;
        margin: 0;
        line-height: 1;
        margin-bottom: 0px;
      }

      .icon-heading {
        display: flex;
        align-items: center;
        margin: 0px 0 20px 0;

        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        h2 {
          font-size: 3rem;
          font-weight: bold;
          // margin: 0;
        }
      }

      p {
        font-size: 20px;
        max-width: 400px;
        line-height: 1.5;
      }
    }

    .right-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      // padding-left: 60px;
      position: relative;

      .list-item {
        display: flex;
        align-items: center;
        margin-left: 10px;
        gap: 24px;

        .number-outline {
          font-size: 4rem;
          color: #4bbb90; /* The fill color of the number */
          text-shadow: -1px -1px 0 #fff, /* Top left */ 1px -1px 0 #fff,
            /* Top right */ -1px 1px 0 #fff, /* Bottom left */ 1px 1px 0 #fff; /* Bottom right */
          font-weight: bold; /* Optional: make the number bold */
        }

        .description {
          font-size: 1.5rem;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
  .card5bg {
    background-image: url('../../../../assets/images/evolveEnergy/card5bgMbl.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .closeContractBG {
    background-image: url('../../../../assets/images/evolveEnergy/closeContractCardBG.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .closeContract2BG {
    background-image: url('../../../../assets/images/evolveEnergy/closeContractCard2BG.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .evolve-financial-overview {
    .headingTitle {
      margin-left: 15px !important;
    }
  }
  .energy-solution-container {
    // width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, #f6fffc, #f6fffc),
      linear-gradient(
        262.09deg,
        rgba(206, 250, 233, 0.56) 6.48%,
        rgba(221, 237, 231, 0.56) 94.97%
      );

    .left-section {
      width: 100%;

      .img-container {
        position: relative;

        .text-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            padding: 16px;
            backdrop-filter: blur(5px);
            background-color: #4141413d;

            h1 {
              font-size: 2rem;
              font-weight: 700;
              color: #ffffff;
              margin: 0;
              margin-bottom: 8px;
            }

            span {
              font-size: 1.2rem;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
      .bImage {
        height: 70vh;
        width: 100%;
      }
    }

    .right-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 32px 0px;
      background: linear-gradient(
        262deg,
        rgba(206, 250, 233, 0.56) 0%,
        rgba(220, 237, 230, 0.56) 100%
      );
      .name-solution {
        width: 90%;

        .name-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h1 {
            font-size: 1.5rem;
            padding: 0.5rem 0;
            font-weight: 600;
            color: #2a2a2a;
          }
        }
        ul {
          list-style: disc;
          margin-left: 3rem;
          padding: 0;
        }
        ul li {
          border: 1px solid;
          border-image-source: linear-gradient(
            90deg,
            #a8d8c6 0%,
            rgba(252, 252, 252, 0) 100%
          );
          padding: 0.5rem 0;
        }
        span,
        li {
          color: #4b4b4b;
          font-weight: 400;
          font-size: 1.3rem;
          padding: 10px;
        }
      }

      hr {
        border: 0;
        height: 1px;
        background: #a8d8c6;
        margin: 10px 0;
      }
    }
  }

  .grant-info {
    // padding: 50px 0px;
    // background-color: #f6fffc; //#f3f3f3;

    h2 {
      // font-size: 3rem;
      color: #2a2a2a;
      // margin-bottom: 20px;
    }
    .texasSolarBg {
      padding: 2rem 1rem;
    }
    .usda-reap-grants {
      // padding: 0 16px;

      p {
        font-weight: 1.5rem;
      }
      .grant-details {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        background-color: #fff;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 20px 20px 0 0;

        .vertical-divider {
          width: 1px;
          height: 100%;
          background-color: #ccc; /* You can adjust the color as needed */
          margin: 0 10px;
        }

        .grant-card {
          // border-radius: 10px;
          padding: 20px;
          flex: 1;
          border-bottom: 1px solid #d6d6d6;
          min-width: 250px;

          &:last-child {
            border-bottom: none;
          }
          h3 {
            font-size: 1.25rem;
            color: #333;
            font-weight: 600;
            margin-bottom: 10px;
          }

          h4 {
            font-size: 1.25rem;
            color: #686868;
            font-weight: 600;
            margin: 0;
          }

          p {
            font-size: 1rem;
            color: #666;
          }

          ul {
            margin: 20px 0;
            padding-left: 20px;
            list-style-type: disc;

            li {
              font-size: 1rem;
              color: #666;
              margin: 10px;
            }
          }

          .green-view {
            // background-color: #437e68;
            padding: 12px;
            width: fit-content;
            border-radius: 8px;
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .eligible-projects {
      background-color: #2a2a2a;
      color: #ffffff;
      padding: 15px;
      display: flex;
      gap: 12px;
      justify-content: start;
      align-items: start;
      flex-direction: column;
      h4 {
        // font-size: 1.25rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
      }
    }

    .ppa-section {
      // padding: 20px;

      h2 {
        font-size: 2rem;
        color: #2a2a2a;
        font-weight: 600;
        margin-bottom: 20px;
      }

      p {
        font-size: 1rem;
        color: #2a2a2a;
        margin-bottom: 10px;
      }
    }

    .diagram {
      display: none;
    }

    .diagramMobile {
      width: 100%;
    }
  }
  .captial-management {
    flex-direction: column;
    padding-top: 0 !important;
    .left {
      h1 {
        font-size: 2rem !important;
      }
      .marginY2 {
        margin: 2rem 0;
      }
    }
    .right {
      display: none;
    }
  }
}

@media (min-width: 769px) {
  .education-certifications {
    min-height: 1000px;
    justify-content: center;
    // padding: 25rem 3rem 7rem 3rem!important;
    .certifications-container {
      .certifications-column {
        ul {
          li {
            font-size: 2rem !important;
          }
        }
      }
    }
  }
  .additional-benefits-container {
    padding: 8rem 100px;
    .headingTitle {
      margin-left: 15px !important;
    }
  }
  .reapGrant {
    background-image: url('../../../../assets/images/evolveEnergy/reapGragBg.svg') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 200px!important;

    .usda-reap-grants {
      background: none !important;
    }
  }
  .investment-opportunity-page {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .content-wrapper {
    flex: 1 1 0;
    align-self: stretch;
    padding: 322px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    position: relative;
    // z-index: 2;
  }

  .headingTitle {
    position: relative;
    margin-left: 40px;

    .highlightImage {
      position: absolute;
      top: -40px;
      left: -40px;
    }
  }
  .logo {
    width: 291px;
    height: 65px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .heading {
    color: white;
    font-size: 100px;
    // font-family: 'Test Founders Grotesk X-Condensed';
    font-weight: 600;
    text-transform: uppercase;
    line-height: 120px;
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 32px;
  }

  .year-wrapper {
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    display: inline-flex;
  }

  .year-highlight {
    width: 4px;
    height: 24px;
    position: relative;
  }

  /* New styles for the Executive Summary section */
  .executive-summary-section {
    display: flex;
    width: 100%;
    height: 100vh; /* Full viewport height */
  }

  .left-side {
    width: 50%;
    position: relative;
  }

  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .right-side {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background: linear-gradient(
      262deg,
      rgba(206, 250, 233, 0.56) 0%,
      rgba(220, 237, 230, 0.56) 100%
    );
    position: relative;
  }

  .summary-content-wrapper {
    max-width: 600px;
  }

  .summary-heading {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .summary-icon {
    width: 4px;
    height: 32px;
  }

  .summary-title {
    color: #2a2a2a;
    font-size: 48px;
    font-family: 'Test Founders Grotesk';
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
  }

  .summary-text {
    margin-top: 16px;
    color: #676767;
    font-size: 18px;
    font-family: 'Outfit';
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
    max-width: 475px;
  }

  .energy-partnerships-container {
    // background-color: #000;
    // color: #fff;
    padding: 90px;
    text-align: center;

    .heading-section {
      margin-bottom: 40px;
      width: 80%;
      text-align: left;

      h1 {
        font-weight: 600;
        font-size: 3rem;
        color: #2a2a2a;
        margin-bottom: 20px;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #2a2a2a;
        font-weight: 400;
      }
    }

    .icons-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;

      .icon-item {
        width: 300px;
        margin: 20px 0;
        text-align: left;
        padding: 20px 40px;

        img {
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
        }

        span {
          display: block;
          font-size: 1.5rem;
          font-weight: 600;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
  }

  .market-tailwind-container {
    display: flex;
    background-color: #3dba91; // Assuming this is the background color from the image
    padding: 80px;
    color: #fff;
    background-image: url('../../../../assets/images/evolveEnergy/greenBG.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .left-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 60px;

      h1 {
        font-size: 5rem;
        font-weight: bold;
        margin: 0;
        line-height: 1;
        margin-bottom: 0px;
      }

      .icon-heading {
        display: flex;
        align-items: center;
        margin: 0px 0 20px 0;

        img {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }

        h2 {
          font-size: 5rem;
          font-weight: bold;
          margin: 0;
        }
      }

      p {
        font-size: 16px;
        max-width: 400px;
        line-height: 1.5;
      }
    }

    .right-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 60px;
      position: relative;

      .list-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 24px;

        .number-outline {
          font-size: 4rem;
          color: #4bbb90; /* The fill color of the number */
          text-shadow: -1px -1px 0 #fff, /* Top left */ 1px -1px 0 #fff,
            /* Top right */ -1px 1px 0 #fff, /* Bottom left */ 1px 1px 0 #fff; /* Bottom right */
          font-weight: bold; /* Optional: make the number bold */
        }

        .description {
          font-size: 2rem;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
  .card5bg {
    background-image: url('../../../../assets/images/evolveEnergy/card5bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .closeContractBG {
    background-image: url('../../../../assets/images/evolveEnergy/closeContractBG.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .closeContract2BG {
    background-image: url('../../../../assets/images/evolveEnergy/closeContract2BG.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // width: 100%;
  }
  .card3BG {
    background-image: url('../../../../assets/images/evolveEnergy/c3bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .education-certifications {
    background-image: url('../../../../assets/images/evolveEnergy/educationCertficationBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 80px;
  }

  .project-growth {
    background-image: url('../../../../assets/images/evolveEnergy/growthBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 1000px;
    display: flex;
    justify-content: center;
    // padding: 6rem 30px;
    .title-container{
      h1{
        font-size: 5rem!important;
      }
    }
    .detail {
      font-size: 2rem!important;
      stong{
        font-size: 2rem!important;
      }
    }
  }
  .secured-funds {
    background-image: url('../../../../assets/images/evolveEnergy/securedBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 80px;
    min-height: 1000px;
    display: flex;
    justify-content: center;
    .headline{
      font-size: 4rem!important;
      width: 60%!important;
    }
    .subtext{
      font-size: 2rem!important;
      width: 60%!important;
    }
  }
  // .stat-name {
  //   font-size: 2rem !important;
  // }
  .energy-solution-container {
    width: 100%;
    display: flex;
    background: linear-gradient(0deg, #f6fffc, #f6fffc),
      linear-gradient(
        262.09deg,
        rgba(206, 250, 233, 0.56) 6.48%,
        rgba(221, 237, 231, 0.56) 94.97%
      );

    .left-section {
      width: 47%;

      .img-container {
        position: relative;

        .text-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            padding: 80px 70px;
            backdrop-filter: blur(5px);
            background-color: #4141413d;

            h1 {
              font-size: 4rem;
              font-weight: 700;
              color: #ffffff;
              margin: 0;
              margin-bottom: 10px;
            }

            span {
              font-size: 1.2rem;
              font-weight: 400;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }
      }
      .bImage {
        height: auto;
        width: 100%;
      }
    }

    .right-section {
      width: 53%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: linear-gradient(
        262deg,
        rgba(206, 250, 233, 0.56) 0%,
        rgba(220, 237, 230, 0.56) 100%
      );
      .name-solution {
        width: 80%;

        .name-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h1 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #2a2a2a;
          }
        }
        ul {
          list-style: disc;
          margin-left: 3rem;
          padding: 1rem 0;
          z-index: 10;
        }
        ul li {
          padding: 1rem;
          border: 1px solid;
          border-image-source: linear-gradient(
            90deg,
            #a8d8c6 0%,
            rgba(252, 252, 252, 0) 100%
          );
          padding: 0.5rem 0;
        }
        hr {
          position: relative;
        }
        hr::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%; /* Full width of the li */
          height: 1px; /* Max height of the border */
          background: linear-gradient(
            to right,
            rgba(168, 216, 198, 1) 0%,
            /* Full opacity at the start */ rgba(168, 216, 198, 0) 100%
              /* Transparent at the end */
          );
        }
        span,
        li {
          color: #4b4b4b;
          font-weight: 400;
          font-size: 1.5rem;
          padding: 10px;
        }
      }

      hr {
        border: 0;
        height: 1px;
        background: #a8d8c6;
        // margin: 10px 0;
      }
    }
  }
  .stat-count {
    font-size: 3rem !important;
    font-weight: 600;
    color: #ffffff;
  }

  .grant-info {
    // padding: 50px 0px;
    // background-color: #f6fffc; //#f8fcf7;

    h2 {
      // font-size: 3rem;
      color: #2a2a2a;
      // margin-bottom: 20px;
    }
    .texasSolarBg {
      background: #f6fffc !important;

      background-image: url('../../../../assets/images/evolveEnergy/texasSolarBg.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 0 40px 100px 40px !important;

      .grant-details {
        border-radius: 20px !important;
      }
    }
    .usda-reap-grants {
      padding: 100px 100px 0px 100px;
      background: #fff;
      p {
        font-weight: 1.5rem;
      }
      .grant-details {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        background-color: #fff !important;
        border-radius: 20px 20px 0 0;
        padding: 2rem 1rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .vertical-divider {
          width: 1px;
          height: 100%;
          background-color: #ccc; /* You can adjust the color as needed */
          margin: 0 10px;
        }

        .grant-card {
          // border-radius: 10px;
          padding: 20px;
          flex: 1;
          border-right: 1px solid #d6d6d6;
          min-width: 250px;

          &:last-child {
            border-right: none;
          }

          h3 {
            font-size: 2rem;
            color: #333;
            font-weight: 600;
            margin-bottom: 10px;
          }

          h4 {
            font-size: 1.25rem;
            color: #686868;
            font-weight: 600;
            margin: 0;
          }

          p {
            font-size: 1rem;
            color: #666;
          }

          ul {
            margin: 20px 0;
            padding-left: 20px;
            list-style-type: disc;

            li {
              font-size: 1rem;
              color: #666;
              margin: 10px;
            }
          }

          .green-view {
            // background-color: #437e68;
            padding: 12px;
            width: fit-content;
            border-radius: 8px;
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .eligible-projects {
      background-color: #339971;
      color: #ffffff;
      padding: 15px;
      display: flex;
      gap: 12px;
      justify-content: center;
      align-items: center;
      h4 {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }

      p {
        font-size: 1rem;
      }
    }

    .ppa-section {
      // padding: 0px 120px 20px 120px;

      h2 {
        font-size: 3rem;
        color: #2a2a2a;
        font-weight: 600;
        margin-bottom: 20px;
      }

      p {
        // font-size: 1rem;
        color: #2a2a2a;
        // margin-bottom: 10px;
      }
    }

    .diagram {
      width: 100%;
    }
    .diagramMobile {
      display: none;
    }
  }
}

.highlight-inner-1 {
  width: 187px;
  height: 68px;
  position: absolute;
  background: #d9d9d9;
  top: -21px;
  left: 0;
}

.highlight-inner-2 {
  width: 191px;
  height: 106px;
  position: absolute;
  background: white;
  top: -20px;
  left: 0;
}

.highlight-line {
  width: 4px;
  height: 66.79px;
  position: absolute;
  background: #2b3505;
  top: -5.17px;
  left: -18px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}

.year-text {
  color: white;
  font-size: 18px;
  font-family: 'Outfit';
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
}

.outline-digits {
  font-weight: bold;
  -webkit-text-stroke: 1px black; /* Outline color and width */
}

.dashBoard-Stats {
  // padding: 36px;
  background-color: #2a2a2a;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .stat-name {
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff;
  }
  .stat-count {
    // font-size: 1.2rem;
    font-weight: 600;
    color: #ffffff;
  }

  .align-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .align-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-row {
    display: flex;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .green-card {
    background-color: #48b087;
    padding: 16px;
    border-radius: 12px;
  }

  .topstats {
    width: 100%;
    display: flex;
    gap: 24px;

    .basicInfo {
      // width: 45%;
      display: flex;
      gap: 24px;
    }

    .revenue {
      background-color: #339971;
      padding: 16px;
      width: 55%;
      border-radius: 12px;
      // display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .bottomstats {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .bottomCard {
      border-radius: 12px;
      padding: 16px;
      // display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100px;
    }

    .energy {
      font-size: 3.5rem;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

.key-opportunities {
  padding: 80px 120px;
  background-color: #f8fcf7;

  .section-header {
    text-align: left;
    margin-bottom: 40px;

    h2 {
      // font-size: 3rem;
      font-weight: 700;
      color: #1e1e1e;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      color: #686868;
    }
  }
  .card-number-new {
    font-size: 4rem;
    color: transparent; /* The fill color of the number */
    text-shadow: -1px -1px 0 #ffffff80, /* Top left */ 1px -1px 0 #ffffff80,
      /* Top right */ -1px 1px 0 #ffffff80,
      /* Bottom left */ 1px 1px 0 #ffffff80; /* Bottom right */
    font-weight: bold;
  }
  .investment-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .investment-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 10px;
      color: #ffffff;

      .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .card-text {
          flex: 1;
          margin-right: 20px;

          .card-number {
            font-size: 4rem;
            color: #4bbb90; /* The fill color of the number */
            text-shadow: -1px -1px 0 #ffffff80,
              /* Top left */ 1px -1px 0 #ffffff80,
              /* Top right */ -1px 1px 0 #ffffff80,
              /* Bottom left */ 1px 1px 0 #ffffff80; /* Bottom right */
            font-weight: bold;
          }

          h3 {
            font-size: 2.5rem;
            margin: 0;
          }

          p {
            font-size: 1rem;
          }
        }

        .card-image {
          flex: 1;
          img {
            max-width: 300px;
            border-radius: 10px;
          }
        }
      }
    }

    .investment-card:nth-child(2) .card-content {
      background-color: #2c2c2c;
    }
  }
}

.captial-management {
  padding-top: 100px;
  display: flex;
  .left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #2a2a2a;
      // font-size: 1rem;
      font-weight: 400;
      line-height: 32px;
    }

    h1 {
      color: #1e1e1e;
      font-size: 3rem;
      font-weight: 600;
    }
  }

  .right {
    flex: 1;

    .image {
      width: 100%;
      height: 100%;
    }
  }
}

.additional-benefits-container {
  background-color: #f1f8f4; /* Light greenish background */
  // padding: 80px;
  // border-radius: 10px;
  position: relative;
  overflow: hidden;

  .header-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start !important;

    .header-icon {
      width: 30px;
      height: 30px;
      /* Include your header icon */
      margin-right: 10px;
    }

    h2 {
      font-size: 3rem;
      font-weight: 600;
      color: #2a2a2a;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: #2a2a2a;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 30px;

    li {
      font-size: 1rem;
      color: #2a2a2a;
      margin-bottom: 10px;
    }
  }

  .testimonial-container {
    background-color: #fff;
    padding: 40px;
    // border-radius: 10px;
    // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    .testimonial-quote {
      font-size: 18px;
      // font-style: italic;
      display: flex;
      flex-direction: column;
      gap: 2;
      color: #333;
      margin-bottom: 15px;
      text-align: left;

      .quote-icon {
        font-size: 36px;
        color: #68b684; /* Adjust color for the quote icon */
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    .testimonial-Author {
      font-size: 16px;
      color: #2a2a2a; //#777;
      // text-align: left; //right;
    }
  }

  /* Background overlay design, add patterns or gradients as needed */
  &:before {
    content: '';
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    opacity: 0.2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: -50px;
    width: 200px;
    height: 200px;
    opacity: 0.2;
  }
}

.end-content {
  width: 100%;
  display: flex;
  .left {
    width: 45%;
    background-color: #1e1e1e;
    // position: relative;

    .text-container {
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        width: 80%;
        font-size: 4rem;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
      .logot {
        width: 80%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .right {
    width: 55%;
  }
}

// .founder-section {
//   display: flex;
//   gap: 40px;
//   padding: 40px;
//   background-color: #fff; // #f0f3f1; // Example background color
// }

.education-certifications {
  background-color: #4c836f; /* The green background color */
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .flower {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    h1 {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
    }

    .icon {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  .certifications-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 25px;

    .certifications-column {
      flex: 1;
      ul {
        list-style-type: disc;
        padding: 0;
        margin: 0;

        li {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
      }
    }
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .education-certifications {
    // padding: 20px;
    background-image: url('../../../../assets/images/evolveEnergy/educationCertificationMblBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10rem 1rem;
    .title-container {
      h1 {
        font-size: 2rem;
      }
    }

    .certifications-container {
      flex-direction: column;
      align-items: flex-start !important;

      .certifications-column {
        margin-bottom: 20px;
        ul {
          li {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

.project-growth {
  background-color: #4bbb90; /* The green background color */
  padding: 100px 80px;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .flower {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .left_vector_design {
    position: absolute;
    left: 0;
    top: 0;
  }
  .right_vector_design {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    h1 {
      font-size: 4.5rem;
      font-weight: bold;
      margin: 0;
    }

    .icon {
      font-size: 3rem;
      margin-bottom: 10px;
    }
  }

  .details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    list-style-type: disc;
    .detail {
      flex: 1;
      font-size: 1.3rem;
      line-height: 1.5;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      strong {
        font-weight: bold;
      }
    }
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .project-growth {
    // padding: 20px;
    background-image: url('../../../../assets/images/evolveEnergy/growthBgMobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 6rem 30px;
    .title-container {
      margin-bottom: 2rem !important;
      h1 {
        font-size: 2.5rem;
      }
    }

    .details-container {
      flex-direction: column;

      .detail {
        font-size: 1.25rem;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
  }
}

.secured-funds {
  background-color: #3c564b; /* The green background color */
  padding: 80px;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .flower {
    position: absolute;
    bottom: 0;
    right: 10px;
    rotate: 180deg;
  }

  .headline {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
    width: 65%;
  }

  .subtext {
    font-size: 1.25rem;
    line-height: 1.5;
    width: 65%;
    strong {
      font-weight: bold;
    }
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .secured-funds {
    background-image: url('../../../../assets/images/evolveEnergy/securedBgMbl.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 13rem 20px;
    .headline {
      font-size: 1.5rem;
      line-height: inherit;
      width: 90%;
    }

    .subtext {
      font-size: 1rem;
      width: 90%;
    }
  }
}

.evolve-financial-overview {
  background-color: #f6fffc;
  // padding: 5rem 2rem;
  color: #2d2d2d;
  text-align: left;

  .section {
    margin-bottom: 40px;

    .leftBorder {
      // padding-left: 30px;
      border-left: 6px #48b087 solid;
      // box-shadow: 0px 8px 124px 0px #1e1e1e0a;
    }
    h2 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      &::before {
        font-size: 1.5rem;
        margin-right: 10px;
      }
    }

    .movement {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      .line-icon {
        width: 4px;
        background-color: #35b46e; /* Green line color */
        margin-right: 20px;
        position: relative;
      }

      .details {
        p {
          // font-size: 1.2rem;
          margin-bottom: 5px;
          font-weight: 400;
          // border-left: 4px #1e1e1e solid;
          padding-left: 10px;
          color: #1e1e1e;
        }

        h3 {
          // font-size: 2.5rem;
          margin: 0;
          line-height: 1.2;
          color: #1e1e1e;
          margin-left: 10px;
        }
      }
    }
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .financial-overview {
    padding: 20px;

    .section {
      margin-bottom: 30px;

      h2 {
        font-size: 1.75rem;
      }

      .movement {
        .line-icon {
          margin-right: 15px;
        }

        .details {
          // p {
          //   font-size: 0.9rem;
          // }

          h3 {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

.contus input, .contus textarea{
  border: none;
  font-size: 1rem;
  font-weight: 700;
  color: #1E1E1E!important;
  padding-left: 0;
}
.contus label {
  font-size: 1rem;
  color: #676767!important;
}