.bannar{
    background-image: url('../Image/bg.png');
    width: 100%;
    height: 800px;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
   
    flex-direction: column;
}
.prow{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.pdesc{
    color: white;
    width: 300px;
}
.ptitle h2{
    color: aliceblue;
    width: 270px;
    text-align: center;
}
.pleft,.pright{
    width: 30px;
    height: 300px;
}
.plogo{
    width: 300px;
    height: 300px;
}
.pbot{
    width: 1000px;
    padding: 50px;
    background-color: #414141;
}
.pbot .pbot-desc{
    color: white;
}
