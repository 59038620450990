body {
  margin: 0;
  font-family: "Outfit", sans-serif;
}
.top-header-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin: 8px 100px 20px;
  padding: 0px 45px;
  border-radius: 100px;
  // background: transparent;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .logoNew {
    color: #0076d0;
    height: 16px;
    padding: 0;
    background: none;
    box-shadow: none;
  }

  nav ul {
    display: flex;
    gap: 32px;
    margin: 0;
    li {
      list-style: none;
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      color: #1e1e1e;
      padding: 14px 0px;
      &:hover,
      &:focus,
      &:active {
        color: #1e1e1e;
        border-bottom: 2px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
        font-weight: 900;
      }
    }

    .li-active {
      list-style: none;
      font-size: 16px;
      cursor: pointer;
      padding: 14px 0px;
      color: #1e1e1e;
      border-bottom: 2px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
      font-weight: 900;
    }
  }

  .get-started {
    font-size: 16px;
    font-weight: 600;
    // color: #0076d0;
    cursor: pointer;
  }

  .menu-burger{
    display: none;
  }
}

.get-startedBtn {
  // font-weight: 400;
  &:hover {
    list-style: none;
    font-size: 16px;
    cursor: pointer;
    padding: 14px 0px;
    color: #1e1e1e;
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(97deg, #0076d0 35%, #0efed4 100%);
    font-weight: 900;
  }
}

// mobile view
@media (max-width: 768px) {
  .header-Section{
    width: "90%"
  }
  .top-header-new {
    padding: 3% 7%;
    nav {
      display: none;
    }
  }
  .get-startedBtn {
    display: none;
  }
  .ham-burger {
    padding: 16px;
  }

  .menu-burger{
    display: inline-block !important;
  }
}

// Desktop view
@media (min-width: 769px) {
  .ham-burger {
    display: none;
  }
}
