.pccon {
  // width: 1140px;
  display: flex;
  justify-content: center;
  background-color: white;
  // border-radius: 30px;
  // margin-top: 30px;
}
.pccard {
  width: 330px;
  padding: 20px;
}
#bb {
  border-right: 1px dashed gray;
  border-left: 1px dashed gray;
}
.cens {
  display: flex;
  justify-content: center;
}
.sban {
  background-color: #e5a811;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.scons {
  color: white;
}
.sno p {
  color: #9e9c9f;
  font-weight: 600;
}
.pcctit h3 {
  color: #0c070f;
}
.pccdecs p{
  color: #767676;
}
