.financial-overview {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 7px rgba(31.08, 37.65, 57.37, 0.04);
    border: 1px solid #e1e5e4;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
  
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
  
      a {
        color: #686868;
        font-size: 12px;
      }
    }
  
    .budget {
      margin-bottom: 20px;
  
      span {
        display: block;
        color: #808b8b;
        font-size: 16px;
        margin-bottom: 5px;
      }
  
      strong {
        font-size: 64px;
        font-weight: bold;
      }
    }
  
    .progress {
      .progress-bar {
        display: flex;
        height: 22px;
        background: #e1e5e4;
  
        .spent {
          width: 292px;
          background: linear-gradient(270deg, #4ea737 0%, #dad200 100%);
        }
  
        .remaining {
          flex-grow: 1;
          background: #e1e5e4;
        }
      }
  
      .info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
  
        span {
          color: #808b8b;
          font-size: 14px;
        }
      }
    }
  }
  