.portfolios {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 6px 7px rgba(31.08, 37.65, 57.37, 0.04);
    border: 1px solid #e1e5e4;
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
  
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
  
      a {
        color: #686868;
        font-size: 12px;
      }
    }
  
    .portfolio-list {
      .portfolio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e1e5e4;
  
        .info {
          display: flex;
          flex-direction: column;
  
          strong {
            font-size: 16px;
          }
  
          a {
            color: #686868;
            font-size: 12px;
          }
        }
  
        .status {
          padding: 4px 8px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: bold;
  
          &.in-review {
            background: #fbf5e6;
            color: #d69c07;
          }
  
          &.approved {
            background: rgba(78, 167, 55, 0.24);
            color: #4ea737;
          }
        }
      }
    }
  }
  