.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .page-item {
    margin: 0 10px;
  }
  
  .page-link, .page-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #ffffff;
    background-color: #262626;
    border-radius: 50%;
    text-decoration: none;
    font-size: 16px;
  }
  
  .page-link:hover {
    background-color: #333333;
  }
  
  .page-info {
    color: #9b9b9c;
    background-color: transparent;
    border: none;
    pointer-events: none;
  }
  