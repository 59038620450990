.mban{
    background-image: url('../Image/bg4.png');
    height:789px;
}
.mfi h1{
    color: white;
    width: 300px;
    position: relative;
    top: 200px;
    left: 200px;
}

.mlist{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
     
    width: 600px;
    top: 300px;
    left:800px;
  
}
.mitem{
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.471);
    padding: 5px;
    color: white;
    border-left: 2px solid #E5A811;
}