.pfin{
    display: flex;
    flex-direction: column;
   background-color: whitesmoke;
    align-items: center;
}
.divide{
    display: flex;
    gap: 10px;
    color: gray;
   
}
.divide .active{
    color: black;
    border-bottom: 1px dashed gray;
}
 .pftit,.divide{
   align-self: baseline;
   margin-left: 360px;
}

.pftit h1{
    margin: 10;
}
.divide p{
    margin: 0;
    padding: 0;
}

 .graph img{
    height: 600px
 }

 