// #our_team{
//     display: flex;
//     justify-content: center;
//     align-items: center ;
//     flex-direction: column;
// }
// .our_team{
//     background-image: url('../../../assets/images/Team.png');
//     width: 1250px;
//     height: 495px;
//     background-repeat: no-repeat;
//     display: flex;
//     flex-direction: column;
    
// }
// .our_team p{
//     width: 600px;
//     font-size: 16px;
//     line-height: 28.4px;
//     color: white;
//     margin:20px;
// }

// .our_team h1{
//     width: 600px;
//     font-size: 48px;
//     line-height: 76.4px;
//     color: white;
//     margin:20px;
// }
// .our_team .mid .btn{
//     margin: 20px;
// }
// .grpd{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     gap: 20px;
// }
// .grpd .iteme,.itme{
//     width: 410px;
//     height: 469px;
// }
// .grpd .itme p{
//     font-size: 24px;
//     font-weight:500 ;
// }
// .joine{
//     width: 100%;
//     background-image: url('../../../assets/images/Background_Noise.png');
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     margin-top: 20px;
//     margin-bottom: 20px;
  
// }


// .joine .jj img{
//     width: 400px;
//     padding: 10px;
// }
// .joine h2{
//     font-size: 48px;
//     margin: 0;
// }
// .joine p{
//     font-size: 16px;
// }
// .mid .btno{
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//     width: 222px;
//     height: 58px;
//     border: 2px solid transparent;
//     background-image: linear-gradient(to right, #007bff, #6610f2);
//     border-radius: 70px;
//     padding: 10px 20px;
//     font-size: 16px;
//     font-weight: bold;
//     color: white;
//     cursor: pointer;
// }

// @media screen and (max-width: 768px) {
//     .our_team{
//         width: 100%;

//     }
//     .our_team h1{
//         width: 250px;
//         font-size: 30px;
//         line-height: normal;
//         margin-bottom: 0;
//     }
//     .our_team p{
//     width: 250px;
//     font-size: 16px;
//     margin-top: 7px;
//     margin-bottom: 0;
//     }
    
   
//     .grpd .iteme{
//         width: 290px;
//         height: 250px;
//     }
//     .grpd .itme{
//         width: 290px;
//         height: 200px
//     }
    
//     .grpd .iteme img:not(.dot){
//         width: 250px;
//         height: 250px;
//         margin-left: 25px;
//     }
//     .joine{
//         flex-direction: column;
//     }
//     .joine .jj{
//         order: -1;
//     }
//     .joine .jj img{
//         width: 300px;
//         padding: 0;
//     }
//     .joine h2{
//         width: 240px;
//         font-size: 30px;
//     }
//     .joine p{
//         font-size: 14px;
//     }
//     .le{
//         margin-bottom: 30px;
//     }

// }
.ot-gradient-circles {
    position: absolute;
    left: 0;
    top: 30%;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.ot-gradient-circles .ot-circle {
    position: absolute;
    width: 288.84px;
    height: 306.35px;
    background: linear-gradient(97deg, #A1B7F1 0%, rgba(24.56, 107.03, 183.16, 0) 100%);
    box-shadow: 120px 120px 120px rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    filter: blur(120.87px);
}

/* Specific circle positions, based on your provided example */
.ot-gradient-circles .ot-circle:nth-child(1) { left: 633.58px; top: 345.79px; }
.ot-gradient-circles .ot-circle:nth-child(2) { left: 609.28px; top: 485.89px; }
