.dashboard-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background: #F0F0F0;
}

.dashboard-layout {
  display: flex;
  height: 100vh; // Full viewport height
}

// .sidebar {
//   width: 250px; // Define a fixed width for the sidebar
//   background: #fff; // Optional: background color for the sidebar
//   height: 100vh; // Ensures the sidebar spans the full viewport height
//   position: fixed; // Keep the sidebar in place during scrolling
//   overflow-y: auto; // Allows the sidebar to scroll independently
//   // z-index: 1000;
// }

.dashboard-main-content {
  margin-left: 300px; // Offset for the sidebar
  flex-grow: 1;
  background: #f7f7f7;
  padding: 20px;
  overflow: auto; // Allows the content area to scroll independently
 // height: 100vh; // Ensures it fills the viewport height
  width: calc(100% - 250px);
  min-height:100vh;
}

.content-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.main-content {
  flex: 1;
  padding: 25px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .header-item {
      width: 734px;
      height: 35px;
      background: #D9D9D9;
    }
  }

  .content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .sidebar-content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .sidebar-content-box {
        background: white;
        box-shadow: 0px 6px 7px rgba(31, 37, 57, 0.04);
        border: 1px solid rgba(33, 39, 50, 0.04);
      }

      .sidebar-content-box:first-of-type {
        width: 307px;
        height: 222px;
      }

      .sidebar-content-box:nth-of-type(2) {
        width: 307px;
        height: 122px;
      }

      .sidebar-content-box:last-of-type {
        width: 307px;
        height: 310px;
      }
    }
  }

  // Add responsive table styles
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
}
